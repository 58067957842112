import React from "react";
import './MyWordCloud.scss';
import {toLink} from "../../helper/stringUtils";

type MyWordCloudProps = {
    wordCloud: [string, number][],
}

function MyWordCloud(props: MyWordCloudProps) {
    const wordCloud = props.wordCloud
        .filter(it => it[0] !== "cryptocurrencies" && it[0] !== "cryptocurrency")
        .slice(0, 60);

    return wordCloud && wordCloud.length > 0
        ? <div className={"myWordCloud"} id={"cloudWrapper"}>
            { wordCloud.map((it, i) => <span className={"cloudItem"} key={"cloud_" + i}><a href={`/${toLink(it[0])}`}>{it[0]}</a><span className={"gray"}>{i === wordCloud.length -1 ? "" : ","}</span> </span>)

            }
            <div id={"btcWordCloud"}/>
        </div>
        : <div/>
}

export default MyWordCloud;
