import React, {useEffect} from "react";
import './DetailsPage.scss';
import '../sidebar/SidebarItem.scss';
import '../mainpage/SmallNewsTile.scss';
import '../mainpage/NewsTile.scss';
import {getHtmlContent, getTeaserText, toLink} from "../../helper/stringUtils";
import {Helmet} from "react-helmet";
import MyWordCloud from "../sidebar/MyWordCloud";
import type {News} from "../model/News";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import SidebarItem from "../sidebar/SidebarItem";
import {getDateString} from "../../helper/dateUtils";
import BigSidebarItem from "../sidebar/BigSidebarItem";
import QAndAForm from "../sidebar/QAndAForm";

type DetailsPageProps = {
    pageContext: {
        data: News,
        wordCloud: [string, number][],
        recentNews: News[],
    }
}

function DetailsPage(props: DetailsPageProps) {
    const news = props.pageContext.data;
    const recentNews = props.pageContext.recentNews;

    useEffect(() => {
        /* eslint-disable no-undef */
        socializer('.socializer');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return <div className={"detailsPage"}>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>BtcLeaks | {news.headline}</title>
            <link rel="canonical" href={`https://btcleaks.com/articles/${toLink(news.headline)}`}/>
            <meta name="description" content={`Full article: ${news.headline}`}/>
            <meta name="keywords" content={`${news.keywords}`}/>
            <meta property="og:title" content={`BtcLeaks | ${news.headline}`}/>
            <meta property="og:description" content={`${getTeaserText(news.content, 200)}`}/>
            <meta property="og:image"
                  content={news.img.startsWith("http") ? news.img : `http://btcleaks.com${news.img}`}/>
            <meta property="og:url" content={`https://btcleaks.com/articles/${toLink(news.headline)}`}/>
            <meta property="og:type" content="article"/>
            <meta property="og:locale" content="en_GB"/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="530"/>
            <meta property="og:image:alt" content={"BtcLeaks.com"} />
            <meta property="og:image:secure_url"
                  content={news.img.startsWith("http") ? news.img : `https://btcleaks.com${news.img}`} />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:site.name" content={"btcLeaks.com"}/>
            <meta property="article:publisher" content="https://btcLeaks.com"/>
            <meta property="article:author" content="BtcLeaks.com"/>
            <meta name="twitter:site" content="@BtcLeaks"/>
            <meta name="twitter:creator" content="Saitama"/>
            <meta name="twitter:card" content="summary"/>
            <script type="application/ld+json">{`
                {
                    "mainEntityOfPage": { 
                        "@type": "WebPage",
                        "@id": "btcleaks.com"
                    },
                    "description": "Full article: ${news.headline}",
                    "dateModified": "${new Date(news.date).toISOString()}",
                    "datePublished": "${new Date(news.date).toISOString()}",
                    "headline": "BtcLeaks | ${news.headline}",
                    "@context": "http://schema.org",
                    "@type": "Article",
                    "publisher": {
                        "@type": "Organization",
                        "name": "btcLeaks.com",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://btcleaks.com${news.img}"
                        }   
                    }
                }`
            }</script>
        </Helmet>
        <article className={"contentWrapper"}>
            <section className={"column1"}>
                <div className={"content"}>
                    <div>
                        <h1>{news.headline}</h1>
                        <div className={"dateAndSocials"}>
                            <div className={"date"}>
                                <small>{getDateString(news.date)}</small>
                            </div>
                            <div className={"socialMediaButtons"}>
                                <div className="socializer" data-features="32px,circle,icon-color,opacity,bg-none,pad"
                                     data-sites="facebook,telegram,twitter,linkedin,reddit" data-text="in"
                                     data-meta-link={`https://btcleaks.com/articles/${toLink(news.headline)}`}
                                     data-meta-title={`${news.headline}`}></div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <img src={news.img} alt={news.headline} title={news.headline}/>
                        <div dangerouslySetInnerHTML={{__html: getHtmlContent(news)}}
                             className={"mainArticle"}></div>
                    </div>
                    <div className={"tags"}>Tags: {
                        news.keywords.split(",").map((it, i) =>
                            <a href={"/" + toLink(it)} className={"keyLink"} title={it} key={"keyword_" + i}> {it}</a>)
                    }
                    </div>
                </div>
            </section>
            <section className={"column2"}>
                <div className={"sidebar"}>
                    <div className="sidebarGroup">
                        {recentNews.length > 0 &&
                        <div className={"sidebarHeadline"}>
                            <a href={"/"} title={"Newest Stablecoin Leaks"} className={"mainLink"}>
                                <span>Recently</span> <FontAwesomeIcon icon={faAngleRight} size={"xs"}/>
                            </a>
                        </div>}
                        {recentNews.slice(0, 1)
                            .map((it, index) => <BigSidebarItem news={it} key={"side3_big_" + index}/>)}
                        {recentNews.slice(1, 100)
                            .map((it, index) => <SidebarItem news={it} key={"side3_" + index}/>)}
                    </div>
                </div>
                <div className="sidebar">
                    <div className="sidebarGroup">
                        <ins className="adsbygoogle"
                             style={{display: "block"}}
                             data-ad-client="ca-pub-6013495598121737"
                             data-ad-slot="9302285469"
                             data-ad-format="auto"
                             data-full-width-responsive="true"/>
                    </div>
                </div>
                <div className="sidebar">
                    <QAndAForm/>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        <div className={"sidebarHeadline"}>
                            <span>Popular</span>
                        </div>
                        <MyWordCloud wordCloud={props.pageContext.wordCloud}/>
                    </div>
                </div>
            </section>
        </article>
    </div>;

}

export default DetailsPage;
