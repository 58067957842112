// @flow
import React from "react";
import "./QAndAForm.scss";

type QandAFormProps = {
}

function QandAForm(props: QandAFormProps) {
    const handleSubmit = e => {
        e.preventDefault();
        const myForm = document.getElementById("qandaForm");
        if (myForm) {
            const formData = new FormData(myForm);
            if (formData) {
                fetch("/", {
                    method: "POST",
                    headers: {"Content-Type": "application/x-www-form-urlencoded"},
                    body: new URLSearchParams(formData).toString(),
                })
                    .then(() => {
                        alert("Form sent successfully.\n\nWe will take care of your question!");
                        myForm.reset();
                    })
                    .catch((error) => alert(error));
            }

        }

    }

    return [
        <div className="qandaForm sidebarGroup" key={"qanda1"}>
            <div className={"sidebarHeadline"}>
                <span>Your Question Wanted</span>
            </div>
            <a href={`/qanda`} title={"We answer to Your Questions!"} className={"qandaForm"}>
                <div className={"imgBox"}
                     style={{backgroundImage: "url('/img_00145.png')"}}
                     title={"We answer to Your Questions!"}
                >
                    <div className={"overlay"}>
                        <div className="date">
                            {"Every Sunday"}
                        </div>
                        <div>
                            <span className="sub">{"We answer to Your Questions!"}</span>
                        </div>
                    </div>
                    <div className={"headline"}>
                        <h3>
                            <span className="main">{"You can find our brand new Q & A Section with all previously asked questions here!"}</span>
                        </h3>
                    </div>
                </div>
            </a>
            <div className={"smallText"}>
                If you have a question right ahead, use the form below and send it to us!
                One question weekly is selected for our Q & A.
            </div>
            <div>
                <form method="POST" data-netlify={"true"} name={"Q & A"} onSubmit={e => handleSubmit(e)}
                      id={"qandaForm"} netlify-honeypot="name">
                    <input type="hidden" name="form-name" value="Q & A"/>
                    <input name="name" className={"name"}/>
                    <div className={"input"}>
                        <input type="text" name="email" required/>
                        <label className={"inputLabel"}>Email</label>
                    </div>
                    <div className={"textarea"}>
                        <textarea name="message" required></textarea>
                        <label className={"inputLabel"}>Question</label>
                    </div>
                    <div className={"button"}>
                        <button type="submit">Send</button>
                    </div>
                </form>
            </div>
        </div>
    ];
}

export default QandAForm;
