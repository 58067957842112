// @flow
import React from "react";
import type {News} from "../model/News";
import {getDateString} from "../../helper/dateUtils";
import {toLink} from "../../helper/stringUtils";
import "./BigSidebarItem.scss";

type BigSidebarItemProps = {
    news: News,
}

function BigSidebarItem(props: BigSidebarItemProps) {
    const {news} = props;
    return (
        <div className="bigSidebarItem">
            <a href={`/articles/${toLink(news.headline)}`} title={news.headline}>
                <div className={"imgBox"}
                     style={{backgroundImage: "url('" + news.img + "')"}}
                     title={news.headline}
                >
                    <div className={"overlay"}>
                        <div className="date">
                            {getDateString(news.date)}
                        </div>
                        <div>
                            <span className="sub">{news.subheadline}</span>
                        </div>
                    </div>
                    <div className={"headline"}>
                        <h3>
                            <span className="main">{news.headline}</span>
                        </h3>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default BigSidebarItem;
